<template>
  <form v-if="paymentVerification"
        name="f1"
        id="f1"
        action="#"
        method="post"
        class="alignet-form-vpos2 d-none">
    <table>
      <tr>
        <td>acquirerId</td><td>
          <input type="text"
                 name="acquirerId"
                 v-model="paymentVerification.acquirerId">
        </td>
      </tr>
      <tr>
        <td>idCommerce</td><td>
          <input type="text"
                 name="idCommerce"
                 v-model="paymentVerification.idCommerce">
        </td>
      </tr>
      <tr>
        <td>purchaseOperationNumber </td><td>
          <input type="text"
                 name="purchaseOperationNumber"
                 v-model="paymentVerification.purchaseOperationNumber">
        </td>
      </tr>
      <tr>
        <td>purchaseAmount </td><td>
          <input type="text"
                 name="purchaseAmount"
                 v-model="paymentVerification.purchaseAmount">
        </td>
      </tr>
      <tr>
        <td>purchaseCurrencyCode </td><td>
          <input type="text"
                 name="purchaseCurrencyCode"
                 v-model="paymentVerification.purchaseCurrencyCode">
        </td>
      </tr>
      <tr>
        <td>language </td><td>
          <input type="text"
                 name="language"
                 value="EN">
        </td>
      </tr>
      <tr>
        <td>shippingFirstName </td><td>
          <input type="text"
                 name="shippingFirstName"
                 :value="getUser.name">
        </td>
      </tr>
      <tr>
        <td>shippingLastName </td><td>
          <input type="text"
                 name="shippingLastName"
                 :value="getUser.surname">
        </td>
      </tr>
      <tr>
        <td>shippingEmail </td><td>
          <input type="text"
                 name="shippingEmail"
                 :value="getUser.email">
        </td>
      </tr>
      <tr>
        <td>shippingAddress </td><td>
          <input type="text"
                 name="shippingAddress"
                 value="Direccion ABC">
        </td>
      </tr>
      <tr>
        <td>shippingZIP </td><td>
          <input type="text"
                 name="shippingZIP"
                 value="ZIP 123">
        </td>
      </tr>
      <tr>
        <td>shippingCity </td><td>
          <input type="text"
                 name="shippingCity"
                 value="CITY ABC">
        </td>
      </tr>
      <tr>
        <td>shippingState </td><td>
          <input type="text"
                 name="shippingState"
                 value="STATE ABC">
        </td>
      </tr>
      <tr>
        <td>shippingCountry </td><td>
          <input type="text"
                 name="shippingCountry"
                 value="PE">
        </td>
      </tr>
      <!--Parametro para la Integracion con Pay-me. Contiene el valor del parametro codCardHolderCommerce.-->
      <tr>
        <td>userCommerce </td><td>
          <input type="text"
                 name="userCommerce"
                 value="modalprueba1">
        </td>
      </tr> <!-- 0101010101 -->
      <!--Parametro para la Integracion con Pay-me. Contiene el valor del parametro codAsoCardHolderWallet.-->
      <tr>
        <td>userCodePayme </td><td>
          <input type="text"
                 name="userCodePayme"
                 value="8--580--4390">
        </td>
      </tr> <!-- 5--420--2340 -->
      <tr>
        <td>descriptionProducts </td><td>
          <input type="text"
                 name="descriptionProducts"
                 value="HBL Booking">
        </td>
      </tr>
      <tr>
        <td>programmingLanguage </td><td>
          <input type="text"
                 name="programmingLanguage"
                 value="PHP">
        </td>
      </tr>
      <!--Ejemplo envío campos reservados en parametro reserved1.-->
      <tr>
        <td>reserved1 </td><td>
          <input type="text"
                 name="reserved1"
                 value="EN">
        </td>
      </tr>
      <tr v-if="paymentVerification && paymentVerification.reserved2">
        <td>reserved2 </td><td>
          <input type="text"
                 name="reserved2"
                 v-model="paymentVerification.reserved2">
        </td>
      </tr>
      <tr v-if="paymentVerification && paymentVerification.reserved3">
        <td>reserved3 </td><td>
          <input type="text"
                 name="reserved3"
                 v-model="paymentVerification.reserved3">
        </td>
      </tr>
      <tr v-if="paymentVerification && paymentVerification.reserved4">
        <td>reserved4 </td><td>
          <input type="text"
                 name="reserved4"
                 v-model="paymentVerification.reserved4">
        </td>
      </tr>
      <tr v-if="paymentVerification && paymentVerification.reserved6">
        <td>reserved6 </td><td>
          <input type="text"
                 name="reserved6"
                 v-model="paymentVerification.reserved6">
        </td>
      </tr>
      <tr v-if="paymentVerification && paymentVerification.reserved7">
        <td>reserved7 </td><td>
          <input type="text"
                 name="reserved7"
                 v-model="paymentVerification.reserved7">
        </td>
      </tr>
      <tr>
        <td>reserved8 </td><td>
          <input type="text"
                 name="reserved8"
                 value="cap_cana">
        </td>
      </tr>
      <tr>
        <td>purchaseVerification </td><td>
          <input type="text"
                 name="purchaseVerification"
                 v-model="paymentVerification.purchaseVerification">
        </td>
      </tr>
    </table>
  </form>
</template>

<script>
export default {
  props: {
    paymentVerification: {
      type: Object,
      default: null
    },
    getUser: {
      type: Object,
      default: null
    },
  },
};
</script>
