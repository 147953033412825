<template>
  <div>
    <myHeader />
    <main
      id="content"
      v-if="booking && booking.property">
      <div class="container my-5">
        <div class="row justify-content-between">
          <section class="col-12 col-lg-6">
            <form class="bookForm">
              <h1>Booking Information</h1>
              <hr>
              <div class="form-group"
                   v-if="getUser && getUser.name">
                <i class="icon-user" />
                <input type="text"
                       v-validate="'required'"
                       name="name"
                       class="form-control"
                       placeholder="Full Name"
                       disabled
                       :value="`${getUser.name} ${getUser.surname}`">
              </div>
              <span class="text-danger small mb-3 d-block fix-margin"
                    v-if="errors.has('name')">{{ errors.first('name') }}</span>
              <div class="form-group"
                   v-if="getUser && getUser.phone">
                <i class="icon-phone" />
                <input type="text"
                       name="phone"
                       v-validate="'required'"
                       v-model="getUser.phone"
                       class="form-control"
                       placeholder="Mobile Phone">
              </div>
              <span class="text-danger small mb-3 d-block fix-margin"
                    v-if="errors.has('phone')">{{ errors.first('phone') }}</span>
              <div class="custom-control custom-checkbox mb-2"
                   v-if="getUser">
                <span class="note text-black"
                      v-if="booking.property.instant_book != 1">
                  Request your reservation for this property and receive an answer within 24 hours. We may make an authorization on your credit card to retain the full amount of the rent.
                  <br>
                  If the reservation is accepted, the authorization hold will become a charge and the amount will be deducted from your credit card.
                  <br>
                  If the request is rejected or it expires, the authorization hold will be canceled in your bank account or it will be shown as a refund.
                  <br><br>
                </span>
                <input name="terms"
                       id="terms"
                       v-model="terms"
                       v-validate="'required'"
                       value="1"
                       type="checkbox"
                       class="custom-control-input">
                <label class="custom-control-label note"
                       for="terms"
                       v-if="booking && booking.property">
                  By clicking <strong>"{{ booking.property.instant_book == 1 ? 'PAY NOW' : 'REQUEST BOOKING' }}"</strong> you are agreeing to our
                  <router-link to="terms"
                               target="_blank">Terms & conditions, </router-link>
                  <router-link to="privacy-policies"
                               target="_blank">Privacy Policies, </router-link>
                  <router-link to="cancellation-policies"
                               target="_blank">Cancellation Policies</router-link>, and to receive booking-related texts. Standard messaging rates may apply.
                </label>
              </div>
              <span class="text-danger small mb-3 d-block fix-margin"
                    v-if="errors.has('terms')">{{ errors.first('terms') }}</span>
              <button class="btn btn-primary"
                      type="button"
                      @click="validateBookingRequest"
                      v-if="getUser"
                      :disable="!paymentVerification">
                {{ booking && booking.property && booking.property.instant_book == 1 ? 'PAY NOW' : 'REQUEST BOOKING' }}
              </button>
              <div
                v-else>
                <button
                  class="btn btn-primary"
                  type="button"
                  data-toggle="modal"
                  data-target="#loginModal"
                  @click="$store.dispatch('updateSignInModalStatus', true)"
                  :disable="!paymentVerification">
                  Sign In
                </button>
                <br><br>
                <button
                  class="btn btn-primary"
                  type="button"
                  data-toggle="modal"
                  data-target="#loginModal"
                  @click="$store.dispatch('updateSignUpModalStatus', true)"
                  :disable="!paymentVerification">
                  Register
                </button>
              </div>
              <!-- <button class="btn btn-primary"
                      type="button"
                      @click="validateBookingRequest"
                      v-if="booking && booking.property && booking.property.instant_book !== 1"
                      :disable="!paymentVerification">
                REQUEST BOOKING
              </button> -->
              <hr>
              <div class="learn-more">
                <i class="icon-mail" />
                <p>Mail <a href="mailto:info@homebelike.com">info@homebelike.com</a> to learn more.</p>
              </div>
            </form>
            <PaymentVerificationForm v-if="getUser"
                                     :payment-verification="paymentVerification"
                                     :get-user="getUser" />
          </section>
          <aside class="col-12 col-lg-4"
                 v-if="booking && booking.property">
            <div class="bookpreview">
              <img class="thumb"
                   :src="booking.property.images && booking.property.images.length > 0 ? booking.property.images[0].medium : ''">
              <div class="info">
                <h2>{{ booking.property.name }}</h2>
                <h4 class="location"
                    v-if="booking.property.state && booking.property.country">
                  {{ booking.property.state.name }}, {{ booking.property.country.name }}
                </h4>
                <hr>
                <label class="calenBook">
                  <i class="icon-calendar" />
                  <div><small>Check In</small> {{ booking.check_in|beatyDate }}</div>
                  <i class="icon-arrow-right" />
                  <div><small>Check Out</small> {{ booking.check_out|beatyDate }}</div>
                </label>
                <label class="calenBook">
                  <i class="icon-users" />
                  <div><small>Travelers</small> {{ booking.travelers }}</div>
                </label>
                <hr>
                <div v-for="(charge, index) in booking.charges"
                     :key="index">
                  <ul>
                    <li>{{ charge.description }} <strong>${{ charge.amount| numFormat('0,0.00') }}</strong></li>
                  </ul>
                </div>
                <hr>
                <div class="total">
                  Total <strong>${{ booking.amount| numFormat('0,0.00') }}</strong>
                </div>
              </div>
            </div>
          </aside>
        </div>
      </div>
    </main>
    <myFooter />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import PaymentVerificationForm from '../../components/PaymentVerificationForm.vue';

export default {
  name: 'BookingRequest',
  data() {
    return {
      booking: {},
      verificationData: {},
      terms: false,
      // user: {},
    };
  },
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: 'Booking Request',
    // all titles will be injected into this template
    // titleTemplate: '%s | Exclusive experience specialists',
    meta: [
      { name: 'description', content: 'Booking Request' },
    ]
  },
  components: {
    PaymentVerificationForm,
  },
  methods: {
    ...mapGetters([
      'getBooking',
    ]),
    validateBookingRequest() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          AlignetVPOS2.openModal('https://vpayment.verifika.com/');
        }
      });
    },
    validateBookingRequestNoPay() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          const formData = new FormData();
          formData.append('booking_id', this.booking.id);
          this.$axios.post('/v1/bookings/request', formData).then(() => {
            this.$router.push({ name: 'user-booking-confirmation', params: { id: this.booking.id } });
            Promise.resolve();
          }).catch((error) => {
            this.error = error.response.data.error;
          });
        }
      });
    }
  },
  created() {
    Promise.all([this.getBooking()]).then((response) => {
      const verification = response[0];
      this.booking = verification;
      if (this.$store.getters.getUser) {
        const URL = this.booking.property.instant_book === 1 ? '/v2/payments/verification/' : '/v2/payments/verification/authorization/';
        this.$axios.get(`${URL}?booking_id=${this.booking.id}`).then((bookingResponse) => {
          this.verificationData = bookingResponse.data.data;
        });
      }
    });
  },
  mounted() {
    setTimeout(() => {
      const alignetsac = document.createElement('script');
      alignetsac.setAttribute('src', 'https://vpayment.verifika.com/VPOS2/js/modalcomercio.js');
      document.head.appendChild(alignetsac);
    }, 1000);
  },
  computed: {
    paymentVerification() {
      return this.verificationData;
    },
    getUser() {
      return this.$store.getters.getUser;
    }
  },
  watch: {
    getUser(newUser) {
      if (newUser) {
        const URL = this.booking.property.instant_book === 1 ? '/v2/payments/verification/' : '/v2/payments/verification/authorization/';
        this.$axios.get(`${URL}?booking_id=${this.booking.id}&user_id=${this.$store.getters.getUser.id}`).then((bookingResponse) => {
          this.verificationData = bookingResponse.data.data;
        });
      }
    }
  }
};
</script>

<style scoped>

</style>
